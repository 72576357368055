import React from 'react';
import './ResultScreen.css';

import jobData from '../jobs.json'; // Import the JSON file

interface ResultScreenProps {
  results: any;
  onRestart: () => void;
  onClose: () => void;
  onFinish: () => void;
}

const StarIcon = ({ active }: { active: boolean }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={active ? "gold" : "currentColor"}
    className="star-icon"
  >
    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
  </svg>
);

const StarRating = ({ rating }: { rating: number }) => (
  <div className='rating'>
    {Array.from({ length: 3 }).map((_, index) => (
      <StarIcon key={index} active={index < rating} />
    ))}
  </div>
);

// Function to get job data by category
const getJobDataByCategory = (category: string) => {
  return jobData.find((job) => job.category === category);
};

function ResultScreen({ results, onFinish, onRestart, onClose }: ResultScreenProps) {
  let sortedResults = Object.entries(results)
    .map(([category, count]: any) => ({ category, count }))
    .sort((a, b) => b.count - a.count);

  sortedResults = sortedResults.slice(0, 3);

  

  // Calculate a rating from 1 to 3 based on the count. The highest count gets a rating of 3. If there are equal ratings, use the same rating for multiple results. Add the rating to a copy of the sortedResults array.
  let rating = 3;
  let previousCount: number | null = null;

  const ratedResults = sortedResults.map((result, index) => {
    if (previousCount !== null && result.count < previousCount) {
      rating--;
    }
    previousCount = result.count;
    return { ...result, rating };
  });

  return (
    <div className="result-screen">
      <h2>Deine Top-Resultate</h2>

      <div className='tiles'>
        {ratedResults.map((result, index) => (
          <div key={index} className='tile'>
            <img src={`${getJobDataByCategory(result.category)?.filename}`} />
            <div className='footer'>
              <div className='left'>
                <h3>{result.category}</h3>
                <p>Hier kommt ein langer Job</p>
              </div>
              
              <StarRating rating={result.rating} />
            </div>
          </div>
        ))}
      </div>
      <button onClick={onFinish}>Test abschließen</button>
      <button onClick={onClose}>Ergebnis verfeinern</button> <br />
      <button onClick={onRestart}>Test wiederholen</button> <br />
    </div>
  );
}

export default ResultScreen;

