import React, { useState } from 'react';
import './ImageSelection.css';

interface ImageSelectionProps {
  images: any[];
  onSelect: (index: number) => void;
}

function ImageSelection({ images, onSelect }: ImageSelectionProps) {
  const handleSelect = (image: any) => {
    onSelect(image);
  };

  return (
    <div className="image-selection">
      <div className="image-grid">
        {images.map((image, index) => (
          <div
            key={index}
            className={`image-container`}
            onClick={() => handleSelect(image)}
          >
            <img src={image.filename} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageSelection;

