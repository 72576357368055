import React, { useState, useEffect } from 'react';
import ImageSelection from './components/ImageSelection';
import ResultScreen from './components/ResultScreen';
import './App.css';

import imageGroupsData from './data.json'; // Import the JSON file

interface Image {
  id: number;
  category: string;
  selected: boolean;
  shownCount: number;
}

const initializeImages = (data: any[]): Image[] => {
  return data.map((item, index) => ({
    id: index,
    category: item.category,
    selected: false,
    shownCount: 0,
    filename: item.filename,
  }));
};

const getRandomImages = (images: Image[], count: number): Image[] => {
  const selectedImages: Image[] = [];
  const usedCategories = new Set<string>();

  while (selectedImages.length < count) {
    const randomIndex = Math.floor(Math.random() * images.length);
    const image = images[randomIndex];

    if (!usedCategories.has(image.category) && image.shownCount < 5) {
      selectedImages.push(image);
      usedCategories.add(image.category);
      image.shownCount++;
    }
  }

  return selectedImages;
};

const App: React.FC = () => {
  const [images, setImages] = useState<Image[]>([]);
  const [currentRound, setCurrentRound] = useState(0);
  const [selections, setSelections] = useState<number[]>([]);
  const [categorySelections, setCategorySelections] = useState<{ [key: string]: number }>({});
  const [activeScreen, setActiveScreen] = useState('intro');
  
  useEffect(() => {
    setImages(initializeImages(imageGroupsData));
  }, []);

  const handleSelection = (selectedImage: any) => {

    setImages(prevImages =>
      prevImages.map(image =>
      image.id === selectedImage.id ? { ...image, selected: true } : image
      )
    );
    setSelections([...selections, selectedImage.id]);

    setCategorySelections(prev => ({
      ...prev,
      [selectedImage.category]: (prev[selectedImage.category] || 0) + 1,
    }));

    setCurrentRound(currentRound + 1);
  };

  useEffect(() => {
    if (currentRound === 12) {
      setActiveScreen('pre-results');
    }
  }, [currentRound, categorySelections]);


  const handleRestart = () => {
    setActiveScreen('intro');
    setCurrentRound(0);
    setSelections([]);
    setCategorySelections({});
    setImages(initializeImages(imageGroupsData));
  };

  const handleCloseResults = () => {
    setActiveScreen('selection');
  };

  const handleFinish = () => {
    setActiveScreen('finish');
  };


  const getNextRoundImages = (): Image[] => {
    const unselectedImages = images.filter(img => !img.selected && img.shownCount < 5);
    const uncertainImages = images.filter(img => img.shownCount === 1 && !img.selected);

    if (unselectedImages.length > 0) {
      return getRandomImages(unselectedImages, 4);
    } else if (uncertainImages.length > 0) {
      return uncertainImages.slice(0, 4);
    } else {
      return [];
    }
  };
  const roundImages = getNextRoundImages();

  return (
    <div className="App">
      <header className="App-header">
        <h1>Stärkenkompass</h1>
      </header>
      <main>

        {activeScreen === 'intro' && (
          <div className='text-screen'>
            <h1>Wo liegen deine Interessen?</h1>
            <p>Finde mit diesem Test heraus was deine Interessen sind und welche Berufe zu dir passen!</p>
            <p>Du siehst gleich jeweils 4 Bilder. Klicke einfach das Bild an, das dich am meisten anspricht.</p>
            <p>Dabei geht es nicht darum, was du kannst sondern was dich anspricht!.</p>
            <button onClick={() => setActiveScreen('selection')}>Start</button>
          </div>
        )}

        {activeScreen === 'selection' && (
          <div>
            <ImageSelection images={roundImages} onSelect={handleSelection} />
            <p>Runde {currentRound + 1}</p>
            {currentRound > 12 && (
              <button onClick={() => setActiveScreen('results')}>Ergebnisse anzeigen</button>
            )}
          </div>
        )}

        {activeScreen === 'pre-results' && (
          <div className='text-screen'>
            <h1>Geschafft!</h1>
            <p>Schau dir an, was dein Test bisher ergeben hat...</p>
            <p>Du kannst auch weitere Bilder auswählen um ein noch genaueres Ergebnis zu bekommen.</p>
            <button onClick={() => setActiveScreen('results')}>Ergebnisse anzeigen</button>
            <br />
            <button onClick={() => setActiveScreen('selection')}>Ergebnis verfeinern</button>
          </div>
        )}

        {activeScreen === 'results' && (
          <ResultScreen results={categorySelections} onFinish={handleFinish} onRestart={handleRestart} onClose={handleCloseResults} />
        )}

        {activeScreen === 'finish' && (
          <div className='text-screen'>
          <h1>Fast fertig.</h1>
          <p>
            Fülle nun bitte noch unseren Fragebogen aus.<br />
            Im Fragebogen wirst du nach einem Code gefragt.<br />
            Der Code lautet:
          </p>
          <p className='survey-code'>maulwurf</p>
          <a className="button" href='https://www.google.com' target='_blank'>Zum Fragebogen</a>
          <br /><br /><br />
          <button onClick={() => handleRestart()}>Test wiederholen</button>
        </div>
        )}
      </main>
    </div>
  );
};

export default App;